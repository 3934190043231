/* .outerContainer{
  width: 100%;
  height: 100%;
  position: relative;
} */

.outerContainer {
  background-image: url("../../../images/LandingScreenImg.png");
  width: 100% !important;
  height: 100vh !important;
  /* overflow: none; */
  /* position: relative; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* filter: blur(4px); */
}

/* .bodyImg {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
  background-size: 100% 100%;
} */

.card1,
.card2 {
  /* width: 18% !important; */
  width: 40%;
  height: 100%;
  /* left: 30%; */
  /* position: absolute; */
  /* top: 10%; */
  cursor: pointer;
  background: azure;
  box-shadow: 10px 10px 10px #065e63;
  -webkit-transition: margin 0.5s ease-out;
  -moz-transition: margin 0.5s ease-out;
  -o-transition: margin 0.5s ease-out;
}
.card1 Button,
.card2 Button {
  text-align: center;
  background: #50dce3;
  width: 100%;
  border: none;
  color: #000000;
  height: 50px;
  font-weight: 900;
}
.card1 Button:hover,
.card2 Button:hover {
  background-color: #55c2c8;
  color: #000000;
}
.card-body {
  padding: 0px;
  /* padding-top: 1.25rem; */
}
.card-text {
  padding: 0px 10px;
  font-style: italic;
  font-family: serif;
  font-size: large;
  font-weight: 600;
}

.card1 img,
.card2 img {
  height: 200px;
  width: 70% !important;
  margin: 0 40px;
}
.iconCss {
  width: 20px;
}

@media screen and  (max-width: 992px) {
  .card1  {
    width: 50%
  }

  .card2 {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .card2 {
    margin-bottom: 30px;
    width: 40%;
  }
  .card1 {
    margin: 30px 0px;
    margin-right: 0;
    width: 40%;
  }
}

@media screen and (max-width: 576px) {
  .card2 {
    margin-bottom: 30px;
    width: 60%;
  }
  .card1 {
    margin: 30px 0px;
    margin-right: 0;
    width: 60%;
  }
}

/* @media screen and (max-width: 575px) {
  .card2 {
    margin-bottom: 30px;
    width: 80%;
  }
  .card1 {
    margin: 30px 0px;
    margin-right: 0;
    width: 80%;
  }
} */

/* @media screen and (max-width: 1226px) {
  .card1 .card2 {
    width: 80%;
  }
}

@media screen and (max-width: 1246px) {
  .card1 .card2 {
    width: 100% !important  ;
  }
} */

/* 1243 */
/* 1227 */
