#newsFeed{
  margin: 20px 10px;
}
#newsFeed h3{
  color: #000;
}
.newsForm{
  background: #fff;
  height: 450px;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px;
  /* overflow-y: auto; */
}
#newsFormInfo textarea,
#newsFormInfo button{
  border: 1px solid #000;
  color: #000;
}
#newsFormInfo .row{
  padding: 10px;
}
#newsFormInfo button{
  background: #fff;
  /* margin: 0 10px; */
}
#newsFormInfo .btnCss button{
  margin: 0 13px;
}
