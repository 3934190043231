.bodyImg {
  width: 100vw;
  height: -webkit-fill-available;
  /* height: 100%; */
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(4px);
  background-size: 100% 100%;
}
.outerContainer1 {
  background-image: url("../../../images/loginImg.png");
  width: 100% !important;
  height: 100vh !important;
  /* position: relative; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* filter: blur(4px); */
}

.blurContainer {
  height: 100vh;
  width: 100vw;
  filter: blur(10px);
}
.innerContainer {
  background-color: #fff;
  width: 100%;
  /* min-width: 200px; */
  height: auto;
  /* display: inline-block; */
  /* position: absolute; */
  /* margin-top: 15%; */
  border-radius: 5px;
  opacity: 0.7;
  /* transform: translateX(-40%) ; */
  /* transform: translateY(-30%); */
}
.logoCss {
  padding-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
section {
  padding: 20px;
}
/* .submitBtn{
  padding: 0 25%;
} */
.registerBtn {
  background: red !important;
  border-color: red !important;
}
.form #registerForm {
  display: none;
}
.submitBtn .btn-success {
  margin-left: 100px;
  margin-right: 20px;
}
#otpForm button,
#loginForm button,
#resetPassword button {
  margin: 0 5px;
}

.loginPgNoteCss {
  text-align: center;
  color: rgb(228, 62, 62);
  /* font-style: oblique; */
  font-weight: bold;
  /* padding-top: 76px; */
  font-family: initial;
  font-size: 15px;
  text-decoration: underline;
}

.formButtons {
  /* padding: 3px; */
  font-size: 15px;
  background-color: green;
  color: #fff;
  border: none;
  padding: 11px 16px;
  border-radius: 5px;
}

.formButtons:focus {
  border: none;
  outline: none;
}

@media screen and (max-width: 387px) {
  .formButtons {
    /* padding: 3px; */
    font-size: 10px !important;
    background-color: green;
    color: #fff;
    border: none;
    padding: 6px 12px !important;
    border-radius: 5px;
  }
}

@media screen and (max-width: 576px) {
  .formButtons {
    /* padding: 3px; */
    font-size: 13px;
    background-color: green;
    color: #fff;
    border: none;
    padding: 7px 10px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .formButtons {
    /* padding: 3px; */
    font-size: 20px;
    background-color: green;
    color: #fff;
    border: none;
    padding: 7px 10px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 992px) {
  .formButtons {
    /* padding: 3px; */
    font-size: 15px;
    background-color: green;
    color: #fff;
    border: none;
    padding: 11px 16px;
    border-radius: 5px;
  }
}
