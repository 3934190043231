#attendance{
      margin: 20px 10px;
}
#attendance h3{
  color: #000;
}
.attendanceDiv{
  background: #fff;
  height: 450px;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px;
  /* overflow-y: auto; */
}

#attendanceForm button,
#attendanceForm input{
  border: 1px solid #000;
  color: #000;
}
#attendanceForm .row{
  padding: 10px;
}
#attendanceForm button{
  background: #fff;
  /* margin: 0 10px; */
}
#attendanceForm .btnCss button{
  margin: 0 13px;
}
.errormsg{
  margin-top: 30px;
  color: red;

}

#attendanceForm .react-datepicker__navigation--previous{
  border-right-color: #ccc !important;
}

#attendanceForm .react-datepicker__navigation--next{
  border-left-color: #ccc !important;
}

#attendanceForm .react-datepicker__navigation{
  background: none;
  cursor: pointer;
  border: 0.45rem solid transparent;
}

.react-datepicker button:focus{
  outline: none;
}
