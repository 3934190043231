

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.sidebar {
  /* margin-top: 100px; */
  padding: 0;
  width: 200px;
  background-color: #f2f3df;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
  border-top: 2px solid;
}
.sidebar a:last-child {
  border-bottom: 2px solid;
}
.sidebar a.active {
  background-color: #5798a7;
  color: white;
  text-decoration: none;
}

.sidebar a:hover:not(.active) {
  background-color: #5798a7;
  color: white;
  text-decoration: none;
}

div.content {
  margin-left: 17%;
  padding: 2px 16px;
  background: #fff;
  height: 100%;
  width: 83%;
  /* position: absolute; */
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.userIcon {
  font-size: 45px;
  padding-top: 3px;
  padding-left: 5px;
}
.headerCss {
  position: fixed;
  width: 100%;
  background: #fff;
  height: 100px;
}
.headerIcon {
  height: 100px;
  width: 200px;
}
#username {
  text-align: left;
  padding-left: 5px;
  font-style: italic;
  font-weight: bold;
}
.square1,
.square2,
.square3,
.square4 {
  color: #5593af !important;
  width: 210px;
  height: 80px;
  background: #fff;
  /* opacity: 0.7; */
  /* border-radius: 5px; */
  /* box-shadow: 5px 5px 5px #7bca8c; */
  box-shadow: 4px 4px 4px 4px #d8d3d3;
  margin: 20px 10px;
  /* min-width: 100px; */
}
.dashboardContent {
  padding: 20px;
  display: flex;
}
.mainContainer {
  background: #dfe4e3;
  min-height: 630px;
  overflow: auto;
}
.square1 {
  color: #249231;
  /* border: 2px solid #249231; */
  font-weight: 900;
  padding: 0 10px;
}
.square1 p,
.square2 p,
.square3 p,
.square4 p {
  font-size: 35px;
  padding-top: 10px;
}
.square2 {
  color: #5593af;
  font-weight: 900;
  padding: 0 10px;
  /* border: 2px solid #249231; */
  /* background: #3099abfa; */
  /* box-shadow: 5px 5px 5px #50b5cc; */
}
.square3 {
  color: #e6ca19;
  font-weight: 800;
  padding: 0 10px;
  /* border: 2px solid #249231; */
  /* background: #d42828; */
  /* box-shadow: 5px 5px 5px #dad540; */
}
.square4 {
  color: #cc2d2d;
  font-weight: 800;
  padding: 0 10px;
  /* border: 2px solid #249231; */
  /* background: #d42828; */
  /* box-shadow: 5px 5px 5px #e87d82; */
}
Popover {
  background: red;
}
#modalCss .modal-title {
  color: green;
  font-weight: 800;
}
#modalCss .tourBtn {
  background: #ececec;
  color: #000;
  border-color: #000;
}
#modalCss .tourBtn:hover {
  background: #b7b7bf;
}
.shepherd-button {
  background: #c3e7ea !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}
.shepherd-button:hover {
  background: #6ab5bb !important;
}
.shepherd-element,
.shepherd-arrow:before {
  background: aliceblue !important;
}
.rowCss {
  margin: 10px 10px;
  /* margin-bottom: 20px; */
  background: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.head {
  height: 50px;
  background: #5d91ab;
  position: fixed;
  width: 100%;
  font-weight: 800;
}
marquee {
  font-size: 18px;
  margin: 10px;
  font-family: sans-serif;
  color: #75dc85;
}
.bottomRowCss {
  margin: 0px 10px;
  background: #fff;
  /* max-height: 430px; */
  /* min-height: 430px; */
  height: 100vh;
  width: 100%;
  /* float: right; */
  overflow: none;
  /* height: 100vh; */
  /* border-top-left-radius: 30px;
border-top-right-radius: 30px; */
}
.Admin,
.HR,
.MD {
  min-height: 550px !important;
  max-height: 550px;
}
.HR {
  margin: 0px 20px;
  border-radius: 10px;
  padding: 10px;
}
.bottomRowCss h5 {
  font-weight: 900;
  color: #5a5a4b;
  padding: 10px;
}
.bottomRowCss hr {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.bottomRowCss .table-bordered th,
.bottomRowCss .table-bordered td {
  border: 0px;
}
.shepherd-element {
  margin: 10px !important;
}

.tabcontent {
  overflow-x: scroll;
}
.notification {
  /* height: 150px;
  background-color: rgba(255, 8, 8, 0.66) !important;
  color: white; */
}
.notification-info {
  height: inherit !important;
  border-radius: 10px !important;
  box-shadow: rgba(218, 195, 49, 0.9) !important;
  border-top: 3px solid rgb(220, 199, 67) !important;
  border-left: 3px solid rgb(220, 199, 67) !important;
  /* width: 450px !important; */
  background-color: #f9f9f9 !important;
}
.notification-message {
  font-weight: 700;
}
.notifications-tr {
  height: -webkit-fill-available !important;
}
.newsIcon,
.upcomingEvtIcon {
  box-shadow: 4px 4px 4px #29de5f;
  float: right;
  /* width: 83%; */
  /* margin-right: 0px; */
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: 20px; */
  /* height:625px; */
  overflow: auto;
  /* position: absolute; */
  position: fixed;
  left: 0;
  bottom: 2;
  z-index: 99999;
  left: 20px;
  bottom: 20px;
  width: auto;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  background: #28b351;
  /* box-shadow: 0 1px 6px rgba(0,0,0,.06), 0 2px 32px rgba(0,0,0,.16); */
  background-repeat: no-repeat;
  background-position: 50%;
  transition: transform 0.15s;
  transition-delay: 0.15s;
}

.recentForm {
  overflow-x: scroll;
}
/* .upcomingEvtIcon {
  float: right;
  right: 0;
  left: 20px;  
  width: auto;
  height: auto;
  border-radius: 10px;
} */


#upcomingEvtModal .notifications-tr {
  left: 0px !important;
}
#upcomingEvtModal .notification-info {
  border-right: 3px solid rgb(220, 199, 67) !important;
  border-left: none !important;
}
#dateDropdown {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  margin: 5px 0px;
}
#filterMonth b {
  margin: 0px 10px;
}
.bottomRowCss th {
  cursor: pointer;
}
.badge {
  background-color: #777;
  color: #ffffff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  border-radius: 50%;
}
.react-bootstrap-table th,
.react-bootstrap-table td {
  text-align: center;
}
.chartCss {
  display: flex;
}
.apexcharts-legend {
  /* display: none !important; */
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
#chartDropdown {
  margin-top: 30px;
  margin-right: 10px;
}
.notifications-tr {
  overflow-y: auto;
}
.bottomRowCss .goog-menu-button {
  display: none;
}
#homeForm .notification-info {
  overflow: auto;
}
.newsDiv {
  color: #0aaaaf;
}
.notifyDiv {
  color: #42b52c;
}
.newsDiv p,
.notifyDiv p {
  margin-bottom: 2px;
  margin-left: 10px;
}
.bottomRowCss table tbody tr {
  white-space: nowrap;
}
.bottomRowCss table tbody tr td:last-child {
  white-space: pre-wrap;
}
.chartTooltip {
  width: 100%;
  height: auto;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s;
}
.shepherd-text {
  overflow: auto !important;
}
.home-Loader {
  margin-left: 15px;
  min-height: 360px;
  width: 70%;
  overflow: auto;
  pointer-events: all;
  z-index: 99999;
  top: 115px;
  left: 0px;
  margin-top: 90px;
  width: 97%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.31);
}
.tourBtn {
  margin-left: 10px;
}

.homeTableBody {
  height: 400px;
  width: 100%;
  overflow-y: scroll !important;
  /* overflow-x: scroll !important; */
}

@media screen and (max-width: 768px){
  .homeTableBody {
    height: 400px;
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }
}
