body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  height: -webkit-fill-available;
  width: 215px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 20px;
  margin: 10px 15px;
  border-radius: 15px;
  border: 2px solid #5593af;
}

.sidenav a {
  text-align: center;
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 18px;
  display: block;
  /* border-bottom: 2px solid #a43e3e;
  background: #d6d3d3; */
    color: #5593af;
    margin: 10px 20px;
    /* border-radius: 10px;
    box-shadow: 4px 4px black; */
        border: 2px solid #5593af;
        font-weight: 700;
}

.sidenav a:hover {
  background: #5593af57;
  color: #000;
}
.active{
  background: #af3136;
  color: #fff;
}
.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
.Link1, .Link2, .Link3, .Link4, .Link5, .Link6, .Link7, .Link9{
  color: #bababc !important;
background-color: #343a40 !important;
border-color: #343a40 !important;
box-shadow: none !important;
}
.Link1:hover, .Link2:hover, .Link3:hover, .Link4:hover, .Link5:hover, .Link6:hover, .Link7:hover, .Link9:hover{
  color: #fff !important;
}

.usernameHeader {
  color: #e1e1f5;
    padding-right: 30px;
    font-weight: bolder;
}
.verticalLine{
  margin: 0 5px;
  border-left: 3px solid #9e9e9f;
  height: 100%;
}
