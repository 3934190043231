.navigation{
  background-color: red;
}
.maintable{
	width: 80%;
	margin-top: 3%;

}
select{
	background-color:#66bb6a;
	color: white;
	height: 30px;
	width: 100px;
	border:1px solid #7ac9b7;
border-radius:5px;
}
option{
	background-color: white;
	color: black;
}
.react-bootstrap-table-editing-cell{
  min-width: 160px;
}
.loader{
	pointer-events: all;
 z-index: 99999;
 top: 115px;
 left: 0px;
 margin-top: 70px;
 width: -webkit-fill-available;
 position: absolute;
 background-color: rgba(0, 0, 0, 0.31);
}
.loader-outer {
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    position: absolute;
}

.loader-inner {
    left: 50%;
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
}
.loaderclass{
    margin-left: 13px;
    min-height: 380px;
    width: 98%;
    overflow: auto;
  }

#applicationListTable button:disabled{
  cursor: no-drop;
}

#applicationListTable .dropdown button:disabled{
      background: rgba(144, 144, 127, 0.2);
}

#applicationListTable tr:nth-child(even) {
  color: rgba(159, 159, 158, 1);
}
#applicationListTable tr:nth-child(odd) {
  color: rgba(144, 144, 127, 1);
}
#applicationListTable th{
  color: #000;
  cursor: default !important;
  text-align: center;
}
#applicationListTable th:nth-child(4),
#applicationListTable th:nth-child(5){
  cursor: pointer !important;
}
hr{
  margin: 10px;
}
h5{
  padding: 15px 0 0 10px;
}
td{
  cursor: default;
  text-align: center;
}
.disableCss{
  pointer-events: none;
  cursor: no-drop;
}
.enableCss, .chatIcon{
  pointer-events: all;
  cursor: pointer;
}
#applicationListTable td .enableCss .fa,
#applicationListTable td .chatIcon .fa{
  font-size: x-large;
  color: #2d8bdc;
}
#applicationListTable td .disableCss .fa{
  font-size: x-large;
  color: #90907f;
}
.chatIcon{
  padding: 0 10px;
}
