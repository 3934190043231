#leave {
  margin: 10px 10px;
}
#leave h4 {
  color: #000;
  padding-top: 0px !important;
  margin-bottom: 0.2rem !important;
}
#leave h6 {
  color: #c32424;
  padding-top: 0px !important;
}
.leaveForm {
  background: #fff;
  height: auto; /* 450px */
  overflow: none;
}

body {
  font-family: Arial;
  background: #9898de4a;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc !important;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  /* border: 1px solid #ccc; */
  border-top: none;
}

#Apply {
  height: auto;
  overflow: none;
}
#Apply span {
  width: 25%;
  font-weight: bold;
}
#Apply .form-inline {
  padding: 5px 0px;
}
#myForm .react-datepicker__day--weekend {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__input-container input {
  cursor: pointer;
}
#leaveBtn {
  margin-left: 27%;
  margin-right: 2%;
}
#Holidays h3 {
  text-align: center;
  color: black;
}
.listDiv {
  /* height: 385px; */
}
.mainDiv {
  text-align: center;
  display: flex;
  overflow: auto;
  height: 370px;
}
.mainDiv .listDiv:first-child {
  border-right: 2px solid #000;
  height: fit-content;
  min-height: 100%;
}
.countryName {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
#dropdown-basic-button {
  background: #fff;
  color: #000;
  border-color: #fff;
  width: 100%;
}
.leaveForm input,
.leaveForm .dropdown {
  border: 1px solid #000;
}
.holidayMsg {
  width: auto !important;
  font-size: small;
  color: #000;
}
#Pending td button {
  margin: 0px 2px;
}
#rejLeave1 {
  background-color: #ff5252;
}
#acptLeave {
  background-color: #66bb6a;
}
#Pending tbody td:last-child {
  display: flex;
}
#personalInfo {
  /* overflow-y: auto;
  overflow-x: hidden; */
}
#Pending {
  /* display: block; */
  height: 325px;
  overflow: auto;
  max-height: 400px;
}
.holidayList {
  text-align: center;
}
.holidayList hr {
  border-top: 2px solid #d8d5d5;
}
.holidayList p {
  margin: 0;
}
#Absent {
  height: 340px;
  overflow: auto;
  max-height: 435px;
}
#Absent form {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.leaveLoader {
  pointer-events: all;
  z-index: 99999;
  /* top: 0px; */
  left: 0px;
  margin-left: 22px;
  margin-right: 22px;
  /* margin-top: 70px; */
  width: -webkit-fill-available;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.14);
  /* margin-top: 19%; */
  /* margin-bottom: 0px; */
  height: 330px;
  /* display: flex; */
  /* flex-wrap: wrap;*/
}

.loader-outer {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.loader-inner {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.leaveSubTitle {
  padding-top: 0px !important;
}

.leaveTitle {
  margin-bottom: 0px !important;
}