.maintable{
    width: 99%;
    height: 500px;
    margin-left: 10px;
    padding: 20px;
    padding-right: 20px;
    text-align: left;
    background-color: white;
	overflow: scroll;
    white-space: nowrap;
}
.maintable th:last-child{
    white-space: nowrap;
}
.highlightRow{
  color: #fff !important;
  background: #ff57578c !important;
}
#userTable th{
  cursor: default;
}
th:nth-child(4),
th:nth-child(5){
  cursor: pointer !important;
}
.load{
    margin-left: 30px;
    margin-top: 52px;
    min-height: 410px;
    width: 96%;
    overflow: auto;
}