#addHoliday{
  margin: 20px 10px;
}
#addHoliday h3{
  color: #000;
}
.holidayForm{
  background: #fff;
  height: 450px;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px;
  /* overflow-y: auto; */
}
#holidayFormInfo input,
#holidayFormInfo button{
  border: 1px solid #000;
  color: #000;
}
#holidayFormInfo .row{
  padding: 10px;
}
#holidayFormInfo button{
  background: #fff;
}
.button{
  background: #fff;
  margin: 0 10px;
}
#holidayFormInfo span,
#selectHolidayType span{
  width: 10%;
  font-weight: bold;
}
.country{
  width: 10%;
}
#holidayFormInfo .form-inline{
  margin: 20px 0px;
}
#holidayFormInfo .button button{
  margin: 0px 5px;
}
#selectHolidayType button{
  background: #fff;
  border: 1px solid #000;
  color: #000;
  width: 100%;
}

#holidayFormInfo .react-datepicker__day--weekend{
  cursor: pointer;
  color: #000;
  pointer-events: all;
}
#holidayFormInfo .react-datepicker__navigation--previous{
  border-right-color: #ccc !important;
}

#holidayFormInfo .react-datepicker__navigation--next{
  border-left-color: #ccc !important;
}

#holidayFormInfo .react-datepicker__navigation{
  background: none;
  cursor: pointer;
  border: 0.45rem solid transparent;
}

.react-datepicker button:focus{
  outline: none;
}

.mainDiv{
  text-align: center;
  display: flex;
  overflow-y: auto;
  height: 370px;
}
.mainDiv .listDiv:first-child{
  border-right: 2px solid #000;
  height: fit-content;
  min-height: 100%;
}
