.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  cursor: pointer;
}
.tooltip button{
  text-align: left;
  background: #50dce3 !important;
  width: 100%;
  border: none;
  color: #000000 !important;
  height: 50px;
  font-weight: 500;
  border-bottom: 1px solid #fff !important;
}
.tooltip button:hover{
  color: #000 !important;
background-color: #55c2c8 !important;
border: none;
border-bottom: 1px solid #fff !important;
}
#tileBtn{
  border: none !important;
}
.tooltip .tooltiptext {
  /* visibility: hidden; */
  cursor: pointer;
  width: 150px;
  background-color: #50dce3;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 0;
}

.tooltip .tooltiptext::after {
  content: "";
    position: absolute;
    bottom: 100%;
    left: 85%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #50dce3 transparent;
}
 .tooltiptext {
  visibility: visible;
}
.iconCss{
  width: 20px;
}
