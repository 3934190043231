#profile{
  margin: 20px 10px;
}
#profile h3{
  color: #000;
}
.profileForm, .userAdd{
  background: #fff;
  height: 460px;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px;
  overflow: auto;
}
.userAdd{
  overflow: auto;
}
.profileForm #personalInfo, .userAdd #personalInfo{
  overflow-y: auto;
overflow-x: hidden;
height: 100%;
}
#personalInfo input,
#personalInfo textarea,
#personalInfo button{
  border: 1px solid #000;
  /* background: #fff; */
  color: #000;
}
#personalInfo .row{
  padding: 10px;
}
#personalInfo button{
  background: #fff;
}
#personalInfo .dropdown-menu.show{
  width: -webkit-fill-available;
}
.userForm{
  margin: 0 5px;
  padding: 0;
}
#personalInfo .react-datepicker__day--weekend{
  /* color: #000;
  pointer-events: all; */
}
.ManagerUser{
  overflow: unset;
}
.ManagerUser #personalInfo{
  overflow-y: unset;
overflow-x: unset;
}
.Removebtn {
  margin-left: 10px;
  margin-top: 10px;
}
.offset-5 {
  margin-left: 2px;
  margin-top: 10px;
  padding: 0px;
}
.errormsg{
  margin-top: 30px;
  color: red;
 
}
.dismsg{
  margin-top: 30px;
  color: red;
}
