#markAbsentForm .react-datepicker__day--weekend {
  color: #ccc !important;
  pointer-events: none !important;
}
#markAbsentForm{
  overflow-y: auto;
overflow-x: hidden;
}
#markAbsentForm input,
#markAbsentForm textarea,
#markAbsentForm button{
  border: 1px solid #000;
  /* background: #fff; */
  color: #000;
}
#markAbsentForm .row{
  padding: 10px;
}
#markAbsentForm button{
  background: #fff;
}
#markAbsentForm .dropdown-menu.show{
  width: -webkit-fill-available;
}
#markAbsentForm .react-datepicker__navigation--previous{
  border-right-color: #ccc !important;
}

#markAbsentForm .react-datepicker__navigation--next{
  border-left-color: #ccc !important;
}
#markAbsentForm .react-datepicker__navigation{
  background: none;
  cursor: pointer;
  border: 0.45rem solid transparent;
}
