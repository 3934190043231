#profile{
  margin: 20px 10px;
}
#profile h3{
  color: #000;
}
.profileForm, .userAdd{
  background: #fff;
  /* height: 450px; */
  height: auto !important;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px;
  overflow: auto;
}
.userAdd{
  overflow: auto;
}
.profileForm #personalInfo, .userAdd #personalInfo{
  overflow-y: auto;
overflow-x: hidden;
}
#personalInfo input,
#personalInfo textarea,
#personalInfo button{
  border: 1px solid #000;
  /* background: #fff; */
  color: #000;
}
#personalInfo .row{
  padding: 10px;
}
#personalInfo button{
  background: #fff;
}
#personalInfo .dropdown-menu.show{
  width: -webkit-fill-available;
}
.userForm{
  margin: 0 5px;
}
#personalInfo .react-datepicker__day--weekend{
  /* color: #000;
  pointer-events: all; */
}
.ManagerUser{
  overflow: unset;
}
.ManagerUser #personalInfo{
  overflow-y: unset;
overflow-x: unset;
}

#personalInfo .react-datepicker__day--weekend{
  cursor: pointer;
  color: #000 !important;
  pointer-events: all !important;
}
#personalInfo .react-datepicker__navigation--previous{
  border-right-color: #ccc !important;
}

#personalInfo .react-datepicker__navigation--next{
  border-left-color: #ccc !important;
}

#personalInfo .react-datepicker__navigation{
  background: none;
  cursor: pointer;
  border: 0.45rem solid transparent;
}

.react-datepicker button:focus{
  outline: none;
}
