* {
/* With these codes padding and border does not increase it's width and gives intuitive style.*/

-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;

 -webkit-font-smoothing: antialiased;

}
.request{
	 background: #9898de4a;
	 height: 622px;
}
.request h3{
	margin-top: 10px;
	margin-left: 20px;

}
.req-form{
  width: 100%;
	height: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: white;
  border-radius:5px;
  border:1px solid black;
}
/* Makes responsive fields. Sets size and field alignment.*/
.req-form input{
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 60px;
  width:300px;
  height: 45px;
  border-radius:5px;
  border:1px solid black;
  background-color: white;

}
.req-form select[name="categories"]{
  color: black;
  height: 45px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 60px;
  width:300px;
  height: 45px;
  border-radius:5px;
  border:1px solid black;
  background-color: white;


}
.req-form textarea[name=desc]{
  height: 60px;
  margin-bottom: -20px;
  resize: none;
  width: 300px;
  border-radius: 5px;
  border:1px solid black;
  margin-top: 20px;
  margin-left: 60px;
}
.req-form button
{
  margin-bottom: 20px;
  margin-top: 40px;
  margin-left: 20px;
  width:100px;
  height: 10px;
  padding: 30px;
  padding-top: 0px;
  border-radius:5px;
  border:1px solid black;
  font-size: 1.1rem;
  cursor:pointer;
}
.req-form label{
	width: 200px;
	margin: 4px 0;
	margin-left: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  background-color: white;

}
.req-form textarea::placeholder{
  color: black;
}
.errmsg{
  margin-left: 300px;
  color: red;

}
